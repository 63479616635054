import React, { useEffect, useState } from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { TwoColumnsLayout } from '../../../common/TwoColumnsLayout'
import { MeetingsList } from './MeetingsList'
import { useParams } from 'react-router-dom'
import {
  useCreateOneToOneNote,
  useGetEmployeeMeeting,
  useGetMeetingNotesFeedback,
  useUpdateOneToOneNote,
} from '@src/api/meetings'
import { FilterByInterface } from '@src/interfaces/data'
import {
  ActionItemsStatus,
  ContentItem,
  GoogleCalendarEventAttendee,
  MeetingEvent,
  MeetingEventStatus,
  MeetingNotesOrigin,
  SummaryStatus,
} from '@src/interfaces/meetings'
import { SummaryHeader } from './components/SummaryHeader'
import { useReviewCycleData } from './hooks/useReviewCycleData'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { ActionButton, Grid, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import { NotesHeader } from './components/NotesHeader'
import { EmptyTableRaw as EmptyState } from '@src/components/Table/EmptyTableRaw'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { MEETING_CALENDAR_LINK } from '@src/constants/externalLinks'
import { SummarySection } from './components/SummarySection'
import { ActionPoints } from './components/ActionPoints'
import { getContentItemsByRole } from './utils/getContentItemsByRole'
import { Attachments } from './components/Attachments'
import { TalkingPointsSection } from './components/TalkingPointsSection'
import { PersonalNotesSection } from './components/PersonalNotesSection'
import { FinalGrade } from '@src/interfaces/performance'

export const OneToOneSummary = () => {
  const [selectedMeeting, setSelectedMeeting] = useState<
    MeetingEvent<GoogleCalendarEventAttendee> | undefined
  >()

  const [meetingList, setMeetingList] = useState<
    MeetingEvent<GoogleCalendarEventAttendee>[]
  >([])
  const [updatingSection, setUpdatingSection] = useState<
    | 'summary'
    | 'talking_points'
    | 'personal_note'
    | 'performance_rating'
    | 'action_items'
    | undefined
  >()
  const [selectedReviewCycle, setSelectedReviewCycle] = useState<FilterByInterface[]>([])

  const params = useParams<{ employeeId: string; meetingId: string; managerId: string }>()
  const openNewTab = useOpenNewTab()

  const employeeId = Number(params.employeeId)
  const meetingId = Number(params.meetingId)
  const managerId = Number(params.managerId)

  const activeFilterCycleId = selectedReviewCycle[0]?.filters[0]?.id

  const {
    isLoading: isReviewCyclesLoading,
    dateRanges,
    reviewCycleOptions,
  } = useReviewCycleData(activeFilterCycleId, setSelectedReviewCycle)

  const {
    data: meetingNotesDetails,
    isLoading: isLoadingMeetingNotesDetails,
    refetch: refetchMeetingNotesFeedback,
  } = useGetMeetingNotesFeedback(selectedMeeting?.id)

  const { data, isLoading: isLoadingEmployeeManagerMeeting } = useGetEmployeeMeeting(
    employeeId,
    meetingId,
  )

  const { mutateAsync: createNotes, isLoading: isLoadingCreateNotes } =
    useCreateOneToOneNote(selectedMeeting?.id)

  const { mutateAsync: updateNotes, isLoading: isLoadingUpdatingNotes } =
    useUpdateOneToOneNote(selectedMeeting?.id)

  const isNotesDetails =
    selectedMeeting && meetingNotesDetails?.id && !isLoadingMeetingNotesDetails

  useEffect(() => {
    if (!isLoadingUpdatingNotes) {
      setUpdatingSection(undefined)
    }
  }, [isLoadingUpdatingNotes])

  const isLoadingUpdatingSummaryNotes =
    isLoadingUpdatingNotes && updatingSection === 'summary'
  const isLoadingUpdatingPersonalNotes =
    isLoadingUpdatingNotes && updatingSection === 'personal_note'
  const isLoadingUpdatingTalkingPoints =
    isLoadingUpdatingNotes && updatingSection === 'talking_points'
  const isLoadingPerformanceRating =
    isLoadingUpdatingNotes && updatingSection === 'performance_rating'
  const isLoadingActionItems =
    isLoadingUpdatingNotes && updatingSection === 'action_items'

  const handleCreateNotes = async () => {
    const payload = {
      manager: { id: managerId },
      report: { id: employeeId },
      origin: MeetingNotesOrigin.Platform,
    }
    await createNotes(payload)
    refetchMeetingNotesFeedback()
  }

  const onChangeSchedule = () => {
    openNewTab(MEETING_CALENDAR_LINK)
  }

  const onCycleChange = (cycle: FilterByInterface) => {
    setSelectedReviewCycle([cycle])
    setSelectedMeeting(undefined)
  }

  const onSummaryChange = async (summary: string) => {
    setUpdatingSection('summary')
    await updateNotes([meetingNotesDetails?.id, { summary }])
    refetchMeetingNotesFeedback()
  }

  const personalNotes = meetingNotesDetails
    ? meetingNotesDetails[
        Object.hasOwn(meetingNotesDetails, 'manager_personal_notes')
          ? 'manager_personal_notes'
          : 'report_personal_notes'
      ]
    : ''

  const onPersonalNotesChange = async (notes: string) => {
    if (meetingNotesDetails) {
      setUpdatingSection('personal_note')
      const updateFieldName = Object.hasOwn(meetingNotesDetails, 'manager_personal_notes')
        ? 'manager_personal_notes'
        : 'report_personal_notes'
      await updateNotes([meetingNotesDetails?.id, { [updateFieldName]: notes }])
      refetchMeetingNotesFeedback()
    }
  }

  const onTalkingPointsChange = async (talkingPoints: string) => {
    onContentChange({ content: talkingPoints }, 'talking_points')
  }

  const isEnabledAI = Boolean(
    meetingNotesDetails?.enable_ai &&
      meetingNotesDetails?.summary_status === SummaryStatus.COMPLETED,
  )

  const onPerformanceRatingChange = (value: { name: string; value: FinalGrade }) => {
    const performanceRating = getContentItemsByRole(
      meetingNotesDetails,
      'performance_rating',
    )?.content

    if (performanceRating) {
      onContentChange({ ...performanceRating, value }, 'performance_rating')
    }
  }

  const onContentChange = async (
    newValue: ContentItem['content'],
    role: ContentItem['role'],
  ) => {
    setUpdatingSection(role)
    const newContent = meetingNotesDetails?.content.map(item => {
      if (item.role === role) {
        item.content = newValue
      }
      return item
    })
    await updateNotes([meetingNotesDetails?.id, { content: newContent }])
    refetchMeetingNotesFeedback()
  }

  const isNotesEmptyState =
    selectedMeeting && !meetingNotesDetails?.id && !isLoadingMeetingNotesDetails

  const actionItems = getContentItemsByRole(meetingNotesDetails, 'action_items')
  const talkingPoints =
    getContentItemsByRole(meetingNotesDetails, 'talking_points')?.content.content || ''

  const isCompletedMeeting = selectedMeeting?.status === MeetingEventStatus.Completed

  const isMeetingListAvailable = Boolean(meetingList.length)

  const isSelectedCompletedMeetingLast =
    meetingList.filter(meeting => meeting.status === MeetingEventStatus.Completed)[0]
      ?.id === selectedMeeting?.id

  const hasEditDisabled =
    selectedMeeting?.status === MeetingEventStatus.Planned
      ? false
      : !isSelectedCompletedMeetingLast

  return (
    <PageWrapper>
      <SummaryHeader
        data={data}
        isLoading={isLoadingEmployeeManagerMeeting}
        onChangeSchedule={onChangeSchedule}
      />

      <CycleFilterButton
        isLoading={isReviewCyclesLoading}
        columnName=""
        filter={selectedReviewCycle}
        onFilterChange={onCycleChange}
        selector={() => Promise.resolve(reviewCycleOptions)}
      />

      <PageBody mt="s-4" maxWidthMd={Token.breakpoint.max}>
        <TwoColumnsLayout
          leftFlex={0}
          leftMinWidth={isMeetingListAvailable ? 270 : '100%'}
          left={
            dateRanges.startDate &&
            dateRanges.endDate && (
              <MeetingsList
                setMeetingList={setMeetingList}
                startDate={dateRanges.startDate}
                endDate={dateRanges.endDate}
                isLoading={isReviewCyclesLoading}
                employeeId={employeeId}
                managerId={managerId}
                selectedMeeting={selectedMeeting}
                setSelectedMeeting={setSelectedMeeting}
              />
            )
          }
          right={
            <>
              <Widget>
                {isNotesEmptyState && (
                  <EmptyState
                    title="You don’t have meeting notes yet"
                    imageId="3D298"
                    description="Record talking points and actions, and take personal notes"
                    action={
                      <ActionButton
                        useIcon="Plus"
                        onClick={handleCreateNotes}
                        pending={isLoadingCreateNotes}
                        disabled={hasEditDisabled}
                      >
                        Create meeting notes
                      </ActionButton>
                    }
                  />
                )}
                {isNotesDetails && (
                  <VStack p="s-16" gap="s-16">
                    <NotesHeader
                      disabled={hasEditDisabled}
                      isLoadingPerformanceRating={isLoadingPerformanceRating}
                      refetchMeetingNotesFeedback={refetchMeetingNotesFeedback}
                      meetingId={selectedMeeting?.id}
                      meetingNotes={meetingNotesDetails}
                      isLoading={isLoadingMeetingNotesDetails}
                      onPerformanceRatingChange={onPerformanceRatingChange}
                    />
                    <Text variant="heading3">{isEnabledAI ? 'AI Notes' : 'Notes'}</Text>
                    <TalkingPointsSection
                      disabled={hasEditDisabled}
                      id={meetingNotesDetails?.id}
                      isLoading={isLoadingUpdatingTalkingPoints}
                      isEnabledAI={isEnabledAI}
                      talkingPoints={talkingPoints}
                      onTalkingPointsChange={onTalkingPointsChange}
                    />
                    {isCompletedMeeting && (
                      <Grid columns={2} gap="s-16">
                        <SummarySection
                          disabled={hasEditDisabled}
                          id={meetingNotesDetails?.id}
                          isLoading={isLoadingUpdatingSummaryNotes}
                          onSummaryChange={onSummaryChange}
                          isEnabledAI={isEnabledAI}
                          summary={meetingNotesDetails?.summary}
                        />
                        <ActionPoints
                          isUpdating={isLoadingActionItems}
                          actionPoints={actionItems?.content || []}
                          disabled={hasEditDisabled}
                          onChange={newValue => onContentChange(newValue, 'action_items')}
                          isGeneratedByAi={
                            meetingNotesDetails?.enable_ai &&
                            meetingNotesDetails?.action_items_status ===
                              ActionItemsStatus.COMPLETED
                          }
                        />
                      </Grid>
                    )}
                    {meetingNotesDetails?.enable_personal_notes && (
                      <PersonalNotesSection
                        disabled={hasEditDisabled}
                        notes={personalNotes}
                        id={meetingNotesDetails?.id}
                        isLoading={isLoadingUpdatingPersonalNotes}
                        isEnabledAI={isEnabledAI}
                        onPersonalNotesChange={onPersonalNotesChange}
                      />
                    )}
                  </VStack>
                )}
              </Widget>
              <Attachments attachments={meetingNotesDetails?.attachments || []} />
            </>
          }
        />
      </PageBody>
    </PageWrapper>
  )
}
