import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Details,
  HStack,
  Widget,
  Text,
  Icon,
  Token,
  VStack,
  TextButton,
  Flex,
  Copyable,
} from '@revolut/ui-kit'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'
import { ActionItem } from '@src/interfaces/meetings'
import { debounce, isNumber } from 'lodash'
import { Actionable } from './Actionable'
import SectionStatus from './SectionStatus'

interface Props {
  actionPoints: ActionItem[]
  isUpdating: boolean
  onChange: (value: ActionItem[]) => void
  isGeneratedByAi: boolean
  disabled: boolean
}

const EMPTY_ACTION_ITEM = { id: 0, value: '', completed: false }

export const ActionPoints = ({
  actionPoints,
  onChange,
  isUpdating,
  isGeneratedByAi,
  disabled,
}: Props) => {
  const [isEdited, setIsEdited] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [actionItems, setActionItems] = useState<(ActionItem & { id: number })[]>(
    actionPoints.length
      ? actionPoints.map((item, index) => ({
          completed: item.completed,
          id: index,
          value: item.value,
        }))
      : [EMPTY_ACTION_ITEM],
  )

  const textAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({})

  const toggleComplete = (id: number) => {
    setIsEdited(true)
    setActionItems(prev =>
      prev.map(item => {
        if (item.id === id) {
          item.completed = !item.completed
        }
        return item
      }),
    )
  }

  const updateContent = (id: number, newContent: string) => {
    setIsEdited(true)
    setActionItems(prev => {
      if (!newContent) {
        const previousItemId = prev.findIndex(item => item.id === id) - 1
        const newActionItems = prev.filter(item => item.id !== id)

        const lastItemId = newActionItems.at(-1)?.id
        const focusRefId = previousItemId >= 0 ? previousItemId : lastItemId
        if (newActionItems.length) {
          if (isNumber(focusRefId)) {
            const textArea = textAreaRefs.current[focusRefId]
            textArea?.focus()
            // Move cursor to the end of the text
            if (textArea) {
              const textLength = textArea.value.length
              textArea.setSelectionRange(textLength, textLength)
            }
          }
          return newActionItems
        }
        return [EMPTY_ACTION_ITEM]
      }
      return prev.map(item => {
        if (item.id === id) {
          item.value = newContent
        }
        return item
      })
    })
  }

  const addNew = () => {
    const newId = actionItems.length
    setActionItems(prev => [...prev, { id: newId, value: '', completed: false }])
    setTimeout(() => {
      textAreaRefs.current[newId]?.focus()
    }, 0)
  }

  const updateDebounced = useCallback(
    debounce((newItems: ActionItem[]) => {
      onChange(newItems)
    }, 1000),
    [],
  )

  useEffect(() => {
    if (!isUpdating) {
      setIsTyping(false)
    }
  }, [isUpdating])

  useEffect(() => {
    updateDebounced(
      actionItems.map(item => ({ completed: item.completed, value: item.value })),
    )
  }, [actionItems])

  const isLoading = isUpdating || isTyping

  return (
    <Widget py="s-12" px="s-16" height="fit-content">
      <Details style={{ paddingTop: 0 }}>
        <Details.Title>
          <SectionStatus
            title="Action points"
            isGeneratedByAi={isGeneratedByAi}
            isEdited={isEdited}
            isLoading={isLoading}
          />
        </Details.Title>
        <Details.Content>
          <Flex alignItems="center" justifyContent="center" height="100%">
            <Copyable
              style={{ color: Token.color.greyTone20 }}
              value={actionItems.map(({ value }) => value).join(' \n')}
              labelButtonCopy="Copy"
              onCopy={() => {
                pushNotification({
                  value: 'Action points are copied to clipboard',
                  duration: SUCCESS_DEFAULT_DURATION,
                  type: NotificationTypes.success,
                })
              }}
            />
          </Flex>
        </Details.Content>
      </Details>
      <VStack gap="s-4">
        {actionItems.map(actionItem => (
          <Actionable
            disabled={disabled}
            actionItem={actionItem}
            key={actionItem.id}
            onEnterPressed={addNew}
            onTextChange={newValue => {
              setIsTyping(true)
              updateContent(actionItem.id, newValue)
            }}
            onChecked={() => {
              toggleComplete(actionItem.id)
            }}
            inputRef={el => {
              textAreaRefs.current[actionItem.id] = el
            }}
          />
        ))}
        {!disabled && (
          <Flex justifyContent="end" width="100%">
            <TextButton onClick={addNew}>
              <HStack gap="s-4" align="center">
                <Icon name="Plus" size={16} />
                <Text variant="caption">Add action point</Text>
              </HStack>
            </TextButton>
          </Flex>
        )}
      </VStack>
    </Widget>
  )
}
