import React, { useCallback, useEffect, useState } from 'react'
import { Flex, Text, VStack, Widget } from '@revolut/ui-kit'
import { BulletPointTextArea } from './BulletPointTextArea'
import SectionStatus from './SectionStatus'
import { debounce } from 'lodash'

interface Props {
  notes: string
  isEnabledAI: boolean
  isLoading: boolean
  onPersonalNotesChange: (value: string) => void
  id: number | undefined
  disabled: boolean
}

export const PersonalNotesSection = ({
  notes,
  isEnabledAI,
  isLoading,
  id,
  disabled,
  onPersonalNotesChange,
}: Props) => {
  const [isEdited, setIsEdited] = useState(false)

  const [isTyping, setIsTyping] = useState(false)

  const debouncedAndThrottledOnChange = useCallback(
    debounce(onPersonalNotesChange, 1000),
    [],
  )

  useEffect(() => {
    setIsEdited(false)
  }, [id])

  useEffect(() => {
    if (!isLoading) {
      setIsTyping(false)
    }
  }, [isLoading])

  return (
    <Widget px="s-16" py="s-12">
      <VStack gap="s-6">
        <Flex justifyContent="space-between" alignItems="center">
          <SectionStatus
            title="Personal notes"
            isGeneratedByAi={isEnabledAI}
            isEdited={isEdited}
            isLoading={isLoading || isTyping}
          />
          <Text variant="emphasis2">Only visible to you</Text>
        </Flex>
        <BulletPointTextArea
          disabled={disabled}
          value={notes || ''}
          onChange={(value: string) => {
            setIsTyping(true)
            setIsEdited(value !== notes)
            debouncedAndThrottledOnChange(value)
          }}
        />
      </VStack>
    </Widget>
  )
}
