import React from 'react'
import { Checkbox, Item, Text, VStack } from '@revolut/ui-kit'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'

interface Props {
  action: GrowthPlanActionInterface
}

export const ActionItem = ({ action }: Props) => {
  // TODO: implement "Mark action as complete" flow in scope of https://revolut.atlassian.net/browse/REVC-7493
  return (
    <Item use="label" disabled>
      <Item.Prefix>
        <Checkbox value={action.id} checked={action.is_completed} disabled />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>
          <VStack>
            {action.description.split('\n').map((line, index) => (
              <Text key={index}>{line.trim()}</Text>
            ))}
          </VStack>
        </Item.Title>
      </Item.Content>
    </Item>
  )
}
