import React from 'react'
import { Avatar, Group, Item, Widget } from '@revolut/ui-kit'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { ActionItem } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/ActionItem'

interface Props {
  growthPlan: GrowthPlanInterface
}

export const ActionItemsWidget = ({ growthPlan }: Props) => {
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Questionnaire" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Growth plan actions</Item.Title>
          <Item.Description>
            To-do items for employee to successfully complete growth plan
          </Item.Description>
        </Item.Content>
      </Item>
      <Group px="s-24" style={transparentThemeBackgroundOverrides}>
        {growthPlan.actions.map(action => (
          <ActionItem key={action.id} action={action} />
        ))}
      </Group>
    </Widget>
  )
}
